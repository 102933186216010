import {
  Avatar,
  Icons,
  Sidebar as SidebarOriginal,
  SidebarButton,
  SidebarFooter,
  SidebarHeader,
  SidebarItems,
} from '@hailtrace/catalyst-ui';
import * as React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export const Sidebar: React.FC = () => {
  const location = useLocation();
  const routes = [
    {
      icon: <Icons.Dashboard size="l" />,
      path: '/',
      exactPath: '/',
    },
  ];

  return (
    <SidebarOriginal>
      <SidebarHeader>
        <Icons.HtLogo style={{ width: '100%', height: '100%' }} />
      </SidebarHeader>
      <SidebarItems>
        {routes.map((el, i) => (
          // @ts-ignore
          <Link to={el.path} key={`route-${i}`}>
            <SidebarButton
              active={
                (el.exactPath && location.pathname === el.exactPath) ||
                location.pathname.includes(el.path)
              }
            >
              {el.icon}
            </SidebarButton>
          </Link>
        ))}
      </SidebarItems>
      <SidebarFooter>
        <SidebarButton>
          <Icons.Cog size="l" />
        </SidebarButton>
        <Avatar size="xl">JP</Avatar>
      </SidebarFooter>
    </SidebarOriginal>
  );
};
