import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import Cookies from 'js-cookie';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { v4 as uuid } from 'uuid';
import cleanTypenameLink from './cleanTypename';

const sessionId = uuid();

// const introspectionQueryResultData = require('../fragmentTypes.json');

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   // @ts-ignore
//   introspectionQueryResultData,
// });
export const authenticationId = () => {
  const storedId = localStorage.getItem('authId');

  if (!storedId) {
    const id = uuid();
    localStorage.setItem('authId', id);
    return id;
  }

  return storedId;
};

const getUrl = (type: 'ws' | 'http'): string => {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  const getProtocol = () => {
    const secure =
      environment === 'production' ||
      environment === 'staging' ||
      environment === 'remote';

    return secure ? `${type}s` : type;
  };

  const protocol = getProtocol();
  switch (type) {
    case 'ws': {
      if (environment === 'staging' || environment === 'production') {
        return `${protocol}://${window.location.host}/subscriptions`;
      }
      if (environment === 'remote') {
        return `${protocol}://one.canopyweather.com/subscriptions`;
      }

      return 'ws://localhost:1337/subscriptions';
    }
    case 'http': {
      if (environment === 'staging' || environment === 'production') {
        return `${protocol}://${window.location.host}/graphql`;
      }
      if (environment === 'remote') {
        return `${protocol}://one.canopyweather.com/graphql`;
      }
      return 'http://localhost:1337/graphql';
    }
    default: {
      throw new Error("Could not generate Apollo Client url's");
    }
  }
};

const wsClient = new SubscriptionClient(getUrl('ws'), {
  reconnect: true,
  connectionParams: {
    authId: authenticationId(),
  },
});
const wsLink = new WebSocketLink(wsClient);
const cache = new InMemoryCache({
  /** fragmentMatcher */
});

export const websocketClient = new ApolloClient({
  link: ApolloLink.from([cleanTypenameLink, wsLink]),
  cache,
});

const getToken = () => {
  const token =
    Cookies.get('hailtrace-identity') || localStorage.getItem('token');

  if (!token) return '';

  return `Bearer ${token}`;
};

const httpLink = new HttpLink({
  uri: getUrl('http'),
  headers: {
    Authorization: getToken(),
    sessionId,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  // @ts-ignore
  link: ApolloLink.from([cleanTypenameLink, splitLink]),
  cache,
});

export default client;
