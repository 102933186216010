import { ApolloProvider } from '@apollo/client';
import {
  canopyLightTheme,
  CssReset,
  ThemeProvider,
} from '@hailtrace/catalyst-ui';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import client from './utils/apolloClient';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  };
  #root {
    min-height: 100%;
  }
`;

ReactDOM.render(
  <>
    <ApolloProvider client={client}>
      <ThemeProvider theme={canopyLightTheme}>
        <CssReset />
        <GlobalStyle />
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
